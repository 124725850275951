import Unicon from "vue-unicons";
import {
  uniRocket,
  uniScrew,
  uniAtom,
  uniFlask,
  uniBracketsCurly,
  uniMoneyBill,
  uniEdit,
  uniTrophy,
  uniUsersAlt,
  uniPen,
  uniTrashAlt,
  uniPercentage,
  uniBook,
  uniLaptop,
  uniUniversity,
  uniFacebook,
  uniTwitter,
  uniLinkedin,
  uniYoutube,
  uniInfoCircle,
  uniAngleLeft,
  uniSearch,
  uniCornerUpLeft,
  uniFolderUpload,
  uniAngleDoubleRight,
  uniAngleDoubleLeft,
  uniCheck,
  uniAngleDoubleDown,
  uniAngleDoubleUp,
  uniFilterSlash,
  uniPlusSquare,
  uniMinusSquare,
  uniMapMarker,
  uniExport,
} from "vue-unicons/dist/icons";

export type IconName =
  | "rocket"
  | "screw"
  | "atom"
  | "flask"
  | "brackets-curly"
  | "money-bill"
  | "edit"
  | "trophy"
  | "users-alt"
  | "pen"
  | "trash-alt"
  | "percentage"
  | "book"
  | "laptop"
  | "university"
  | "facebook"
  | "twitter"
  | "linkedin"
  | "youtube"
  | "info-circle"
  | "angle-left"
  | "corner-up-left"
  | "folder-upload"
  | "search"
  | "angle-double-down"
  | "angle-double-up"
  | "filter-slash"
  | "plus-square"
  | "minus-square"
  | "angle-double-left"
  | "angle-double-right"
  | "map-marker"
  | "check"
  | "export";

Unicon.add([
  uniRocket,
  uniScrew,
  uniAtom,
  uniFlask,
  uniBracketsCurly,
  uniMoneyBill,
  uniEdit,
  uniTrophy,
  uniUsersAlt,
  uniPen,
  uniTrashAlt,
  uniPercentage,
  uniBook,
  uniLaptop,
  uniUniversity,
  uniFacebook,
  uniTwitter,
  uniLinkedin,
  uniYoutube,
  uniInfoCircle,
  uniAngleLeft,
  uniCornerUpLeft,
  uniFolderUpload,
  uniSearch,
  uniAngleDoubleDown,
  uniAngleDoubleUp,
  uniFilterSlash,
  uniPlusSquare,
  uniMinusSquare,
  uniAngleDoubleLeft,
  uniCheck,
  uniAngleDoubleRight,
  uniMapMarker,
  uniExport,
]);

export default Unicon;
